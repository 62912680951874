




































































































import Vue from "vue";
import formSchemaSource from "@/common/form/about";
export default Vue.extend({
  data() {
    return {
      id: 1,
      formSchemaSource,
      form: {},
      home: {},
      data: {},
      categorys: [],
      services: [],
      certificates: [],
      partners: [],
    };
  },
  mounted() {
    this.getAllDatas();
    this.doAnimation();
  },

  methods: {
    getAllDatas() {
      let data = this.$store.state.allData;
      this.certificates = data["certificates"];
      this.data = data["about"][0];
      this.home = data["home"][0];
    },

    goService(id: number) {
      console.log(id);
    },
    doAnimation() {
      this.$ScrollReveal().reveal(".banner_text", {
        reset: true,
        duration: 1000,
        distance: "150%",
        origin: "top",
        scale: 0.8,
      });
      this.$ScrollReveal().reveal(".part1_content", {
        duration: 2000,
        distance: "300px",
        origin: "right",
        scale: 0.8,
      });
      this.$ScrollReveal().reveal(".part2,.part3", {
        duration: 2000,
        distance: "300px",
        origin: "top",
        scale: 0.8,
      });
    },
  },
});
