
import images from "./widget/images"
export default
    {
        "type": "object",
        "properties": {
            "banner": images({}),

            "slogan": {
                "type": "string",
                "ui": {
                    "label": "slogan"
                }
            },
            "subslogan": {
                "type": "string",
                "ui": {
                    "label": "subslogan"
                }
            },
            "introduce": {
                "type": "string",
                "ui": {
                    "label": "introduce",
                    "widget": "editor"
                }
            },
            "bossIntroduce": {
                "type": "string",
                "ui": {
                    "label": "bossIntroduce",
                    "widget": "editor"
                }
            },
            "bossAvatar": images({}),
            "managerIntroduce": {
                "type": "string",
                "ui": {
                    "label": "managerIntroduce",
                    "widget": "editor"
                }
            },
            "managerAvatar": images({}),
            "teamIntroduce": {
                "type": "string",
                "ui": {
                    "label": "teamIntroduce"
                }
            },
            "teamImages": images({}),




        }
    }